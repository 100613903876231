import React, { useEffect, useState } from "react";
import withError from "./hocs/withError";
import RCSlider from "rc-slider";

import "rc-slider/assets/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withLabel from "./hocs/withLabel";
import SLIDER_DISPLAY_COMPONENTS from "../../constants/SLIDER_DISPLAY_COMPONENTS";
import InternationalisationService from "../../InternationalisationService";
import withContainer from "./hocs/withContainer";
import {typeHelper} from "atom5-branching-questionnaire";
import GeneralHelpers from "../../helpers/GeneralHelpers";

const Slider = ({ question, value, changeValue, isReadOnly }) => {
  const [internalValue, setInternalValue] = useState(value);

  const onChange = (newValue) => {
    setInternalValue(newValue);
    changeValue(newValue)
  };

  // The language is required as config is only automatically translated to
  // one level deep.
  const [lang, setLang] = useState(null);
  useEffect(() => {
    const init = async () => {
      let selectedLanguage = await InternationalisationService.getLanguage();
      setLang(selectedLanguage);
    };
    init();
  }, []);

  const min = parseFloat(question.min);
  const max = parseFloat(question.max);
  const step = question.config?.step ? question.config?.step : 1;

  let marks = {};
  const icons = question.config?.icons;

  const showNumbers = typeHelper.parseBool(question.config?.showNumbers);
  if(showNumbers){
    const range = GeneralHelpers.range(min, max, step)
    range.forEach(n=>{
      marks[n] = n;
    })
  }

  if (question?.config?.marks && Array.isArray(question?.config?.marks)) {
    if (lang) {
      marks = Object.fromEntries(
        question.config.marks.map(
          ({ label, value, style, useMarkup }, index) => {
            let Component;
            let isLastLabel = question.config.marks.length - 1 === index;
            if (question.config.marksComponent) {
              switch (question.config.marksComponent) {
                case "VALUE_LABEL":
                  Component = SLIDER_DISPLAY_COMPONENTS.VALUE_LABEL;
                  break;
                default:
                  console.warn(
                    "[Slider] marksConfig supplied value not supported."
                  );
                  break;
              }
            }

            if (Component) {
              return [
                value,
                <Component
                  label={label}
                  value={value}
                  lang={lang}
                  style={style}
                  useMarkup={useMarkup}
                  isLastLabel={isLastLabel}
                />,
              ];
            } else {
              let languageSpecificLabel = "";
              if (label && lang && lang in label) {
                languageSpecificLabel = label[lang];
              }
              return [value, { label: languageSpecificLabel, style: style }];
            }
          }
        )
      );
    }
  } else if (icons) {
    marks = Object.fromEntries(
      icons.map(({ icon, colour }, index) => {
        const numberOfIcons = icons.length;

        // Justify the icons based on the number provided and the number of data points;
        let relativePosition = index / (numberOfIcons - 1);
        let actualPosition = relativePosition * Math.abs(max - min) + min;

        let positionIndex;
        const stepNormalizedValue = (actualPosition / step).toPrecision(2);
        if (relativePosition >= 0.5) {
          positionIndex = Math.ceil(stepNormalizedValue) * step;
        } else {
          positionIndex = Math.floor(stepNormalizedValue) * step;
        }

        // icon: "fas fa-coffee"
        const iconValues = icon.split(" ");
        // iconValues: ["fas", "fa-coffee"]
        // Take the actual icon string and strip off the 'fa'
        iconValues[1] = iconValues[1].split("-").slice(1).join("-");
        // iconValues: ["fas", "coffee"]
        return [
          positionIndex,
          <FontAwesomeIcon icon={iconValues} color={colour} />,
        ];
      })
    );
  } else {
    marks[min] = min;
    marks[max] = max;
    if (typeof internalValue === "number") {
      if (internalValue !== min && internalValue !== max) {
        marks[internalValue] = internalValue;
      }
    }
  }

  return (
    <div
      data-question-answer-value={internalValue}
      style={{
        padding: question?.config?.marksSpacing
          ? question?.config?.marksSpacing
          : question?.config?.marks
          ? "4rem 2rem"
          : "0rem",
      }}
    >
      <RCSlider
        onChange={onChange}
        min={min}
        max={max}
        step={step}
        defaultValue={internalValue}
        styles={{
            handle: {
                backgroundColor: "#fff",
                border: "2px solid #f8991d",
                outline: "2px solid #f8991d",
                opacity: 1

            },
            track: { backgroundColor: "#f8991d" },
            rail: { backgroundColor: "#444" }
        }}
        dotStyle={{ backgroundColor: "#444", border: "0" }}
        activeDotStyle={{
          backgroundColor: "#444",
          border: "0",
        }}
        marks={marks}
        style={{
          marginLeft: 10, 
          marginBottom: "20px",
          maxWidth: "calc(100% - 20px)",
          minWidth: "110px",
          backgroundColor: null,
        }}
        disabled={isReadOnly}
      />
    </div>
  );
};

export default withContainer(withLabel(withError(Slider)));
