import React, { Component } from "react";
import DateTime from "react-datetime";
import moment from "moment";
import InternationalisationService from "../InternationalisationService";
import { Button, Form } from "semantic-ui-react";
import "../react-datetime.css";
import DateTimeService from "../services/DateTimeService";
import { withTranslation } from "react-i18next";

//note: moment and luxon use different case for date formats e.g. dd/MM/yyyy (luxon) vs DD/MM/YYYY (moment)
const MONTH_YEAR_FORMAT_MOMENT = "MM/YYYY";
const DATE_FORMAT_MOMENT = "DD/MM/YYYY";
const TIME_FORMAT_MOMENT = "HH:mm";

const MONTH_YEAR_PLACEHOLDER = "00/0000";
const DATE_TIME_PLACEHOLDER = "00/00/0000 00:00";
const DATE_PLACEHOLDER = "00/00/0000";
const TIME_PLACEHOLDER = "00:00";

class DateTimeField extends Component {
  constructor(props, context) {
    super(props, context);

    this.ref = React.createRef()
    const mode = this.props.mode || "datetime";

    const valueFormat =
      (mode !== "time"
        ? ""
        : mode === "monthyear"
        ? MONTH_YEAR_FORMAT_MOMENT
        : DATE_FORMAT_MOMENT) +
      (mode === "datetime" ? " " : "") +
      (mode !== "date" || mode === "monthyear" ? "" : TIME_FORMAT_MOMENT);

    let placeholderFormat = DATE_TIME_PLACEHOLDER;
    if(mode === 'time') placeholderFormat = TIME_PLACEHOLDER
    if(mode === 'date') placeholderFormat = DATE_PLACEHOLDER
    if(mode === 'monthyear') placeholderFormat = MONTH_YEAR_PLACEHOLDER

    let value;
    if (!this.props.value) {
      value = "";
    }

    if (moment.isMoment(this.props.value)) {
      value = this.props.value;
    }

    if (typeof value === "undefined") {
      value = moment(this.props.value).format(valueFormat);
    }

    this.state = {
      key: (Math.random() * 1000).toString(),
      value: value,
      lang: InternationalisationService.getLanguage(),
      showTime: true,
      mode: mode,
      isValid: true,
      readOnly: this.props.readOnly,
      placeholder: placeholderFormat
    };
  }

  componentDidMount() {
    this.initialiseValue(); 
  }

  initialiseValue() {
    const isDeviceTimestamp = this.props?.config?.type === "DEVICE_TIMESTAMP";
    if (isDeviceTimestamp) {
      const isReadOnly = this.state.readOnly; // Use readOnly to see if questionnaire is completed or not.
      this.setState({readOnly: true});
      if (!isReadOnly) {
        this.handleNow();
      }      
    }
  }

  isValid = (currentDate, selectedDate) => {
    let result = true;
    if (this.props.min) {
      result = moment(currentDate)
        .add(1, "day") // add a day so the min is inclusive
        .isSameOrAfter(moment(this.props.min));
    }
    if (this.props.max) {
      result = moment(currentDate)
        .add(1, "day") // add a day so the max is inclusive (TODO: verify this)
        .isSameOrBefore(moment(this.props.max));
    }
    return result;
  };

  onChange = (value) => {
    if (this.state.mode === "datetime") {
      this.setState({ showTime: true });
    }

    // The component is supposedly controlable with state, however its
    // value is actually its own internal state that can update. Setting the value
    // results in strange behaviour, so now we let it track state, and simply pass
    // the new value up.
    if (moment.isMoment(value)) {
      this.props.onChange(value);
    } else {
      this.props.onChange(null)
    }
  };


  getFormattedValue = () => {
    if (this.state.mode === "date") {
      return DateTimeService.build.asDisplayDate(this.state.value);
    } else if (this.state.mode === "datetime") {
      return DateTimeService.build.asDisplayDateTime(this.state.value);
    } else if (this.state.mode === "monthyear") {
      return DateTimeService.build.asDisplayMonthYear(this.state.value);
    } else if (this.state.mode === "time") {
      return this.state.value.format(TIME_FORMAT_MOMENT);
    }
  };

  handleNow = () => {
    const value = moment();
    this.setState({ value });
    this.props.onChange(value);
  };

  render() {
    let translationKey = "PICK_DATETIME";
    switch (this.state.mode) {
      case "date":
        translationKey = "PICK_DATE";
        break;
      case "time":
        translationKey = "PICK_TIME";
        break;
      default:
        break;
    }

    return (
      <>
        {!this.state.readOnly && this.props.nowOnly && (
          <Button onClick={this.handleNow} primary>
            <p>
              {this.state.value.isValid()
                ? this.getFormattedValue()
                : this.props.t(translationKey, "Click here")}
            </p>
          </Button>
        )}
        {!this.state.readOnly && !this.props.nowOnly && (
          <Form.Field>
            <label>{this.props.label}</label>
            <DateTime
                  initialValue={this.props.value ? this.props.value : undefined}
                  onChange={this.onChange}
                  dateFormat={
                    this.state.mode === "time"
                        ? false
                        : this.state.mode === "monthyear"
                            ? MONTH_YEAR_FORMAT_MOMENT
                            : DATE_FORMAT_MOMENT
                  }
                  timeFormat={
                      this.state.mode !== "date" &&
                      this.state.mode !== "monthyear" &&
                      this.state.showTime &&
                      TIME_FORMAT_MOMENT
                  }
                  isValidDate={this.isValid}
                  locale={this.state.lang}
                  inputProps={{placeholder: this.state.placeholder}}
              />
          </Form.Field>
        )}
        {this.state.readOnly && (
          <Form.Input
            data-question-answer-value={this.getFormattedValue()}
            label={this.props.label}
            type="text"
            value={this.getFormattedValue()}
            disabled={true}
            style={this.props?.style?.input}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(DateTimeField);
